/**
 * Generate a random number
 * @param minRange - This is the minimum range value
 * @param maxRange - This is the maximum range value
 * @return - A random number withing the min and max Range
 *
 * @example
 * generateRandomNumber(1,10) generates a random Number between 1 and 10
 */
export const generateRandomNumber = (minRange: number, maxRange: number): number => {
  return Math.floor(Math.random() * (maxRange - minRange + 1)) + minRange
}

const roundTo = (valuationValue: number, precision: number) => {
  if (precision > 0) {
    return parseFloat(((valuationValue - 1) * 100).toFixed(precision))
  }
  return Math.round((valuationValue - 1) * 100)
}

export const getValuationValue = (
  baseValue: number,
  comparisonValue: number,
  precision = 0,
  calculationType: 'divide' | 'subtract' = 'subtract'
): { valuationValue: number; valuationString: 'High' | 'Equal' | 'Low' } => {
  if (calculationType === 'divide') {
    const valuationValue = baseValue === 0 ? 1 : comparisonValue / baseValue
    if (valuationValue === 1) {
      return { valuationValue: 0, valuationString: 'Equal' }
    }
    if (valuationValue > 1) {
      const newVal = roundTo(valuationValue, precision)
      return { valuationValue: newVal, valuationString: 'High' }
    }
    const newVal = roundTo(valuationValue, precision)
    return { valuationValue: newVal, valuationString: 'Low' }
  }

  const valuationValue = (comparisonValue - baseValue) * 100
  if (valuationValue === 0) {
    return { valuationValue: 0, valuationString: 'Equal' }
  }
  if (valuationValue > 0) {
    return {
      valuationValue: parseFloat(valuationValue.toFixed(precision)),
      valuationString: 'High',
    }
  }
  return { valuationValue: parseFloat(valuationValue.toFixed(precision)), valuationString: 'Low' }
}

export const numberToNumberAbbreviation = (value: number, precision = 0) => {
  if (value > 999999999) {
    return `${(value / 1000000000).toFixed(precision)}B`
  }
  if (value > 999999) {
    return `${(value / 1000000).toFixed(precision)}M`
  }
  if (value > 999) {
    return `${(value / 1000).toFixed(precision)}K`
  }

  return `${value.toFixed(precision)}`
}
