import { CookieDataOptions } from '_v2/services/cookies/models'
import { getRandomElementFromArray } from 'utils/array'

const firstNames = [
  'John',
  'Angela',
  'Caylea',
  'Kyle',
  'Laura',
  'Lana',
  'Mike',
  'Fred',
  'Tom',
  'Adam',
]
const lastNames = [
  'Smith',
  'Todd',
  'Barnes',
  'Doe',
  'Barry',
  'Laux',
  'Zuniga',
  'Butcher',
  'Gardes',
  'McPerson',
]

export const propertiesNames = [
  'Ballast Point',
  'Fort George',
  'Reuben Manor',
  'Rouge River',
  'Georgetown',
  'Palmdale Flats',
  'Elmdale Hill',
]

export const getRandomName = () =>
  `${getRandomElementFromArray(firstNames)} ${getRandomElementFromArray(lastNames)}`

export const getRandomPropertyName = () => getRandomElementFromArray(propertiesNames)

export const randomNumberFromRange = (max: number, min: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const getDomainNameFromSubDomain = (): string => {
  const hostname = window.location.hostname

  if (!hostname) {
    return '*'
  }

  const subdomainDots = (hostname.match(/\./g) || []).length
  if (subdomainDots >= 2) {
    const arrayOfDomainComponents = hostname.split('.')
    return `.${arrayOfDomainComponents[arrayOfDomainComponents.length - 2]}.${
      arrayOfDomainComponents[arrayOfDomainComponents.length - 1]
    }`
  }
  return hostname
}

export const cookieConfigOptions = (): Partial<CookieDataOptions> => {
  const hostname = getDomainNameFromSubDomain()
  return {
    path: '/',
    domain: hostname,
    secure: hostname !== 'localhost',
    'max-age': 60 * 60 * 24 * 30, // cookie exp time is same as token exp time i.e 1 month
    sameSite: 'Strict',
  }
}
